import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Animation from '../images/ani-graph.inline.svg';
import Form from '../components/form';

const AppointmentPage = () => {
    return (
        <Layout>
            <Seo
                title="Appointment"
                description="Life Insurance and Family Planning"
            />
            <div className="app-title">
                <div className="title-txt">
                    <h1
                        data-sal="slide-up"
                        data-sal-duration="500"
                        data-sal-delay="400"
                        data-sal-easing="easeOutCubic"
                    >
                        Appointment
                    </h1>
                    <p
                        data-sal="slide-up"
                        data-sal-duration="500"
                        data-sal-delay="600"
                        data-sal-easing="easeOutCubic"
                    >
                        Request an Appointment with us
                    </p>
                </div>
                <div className="app-ani">
                    <Animation />
                </div>
            </div>
            <div className="content">
                <div className="container">
                    <div className="grid">
                        <div className="col-lg-12">
                            <div className="appointmenttxt">
                                <p>
                                    You can request an appointment by contacting
                                    us via the details below, or alternatively
                                    filling out the appointment form below and a
                                    member of our team will get back to you as
                                    soon as possible.
                                </p>
                                <h1>Phone</h1>
                                <p>
                                    <a href="tel:6323 8691">+61 8 6323 8691</a>
                                    <br />
                                    <a href="tel:9284 1811">+61 8 9284 1811</a>
                                </p>
                                <h1>Email</h1>
                                <p>
                                    <a href="mailto: ashley@lifespanwa.com.au">
                                        admin@lifespanwa.com.au
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="app-form">
                <div className="container">
                    <Form />
                </div>
            </div>
        </Layout>
    );
};

export default AppointmentPage;
