import React, { useState } from 'react';

const Form = () => {
    const [submitDisabled, setSubmitDisabled] = useState('');
    const [formData, setFormData] = useState({
        api_token: process.env.GATSBY_MAIL_API,
        app_id: process.env.GATSBY_MAIL_APP_ID,
        name: '',
        company: '',
        email: '',
        service: '',
        hear: '',
        enquiry: '',
    });
    const [formClass, setFormClass] = useState({
        name: '',
        company: '',
        email: '',
        service: 'select',
        hear: 'select',
        enquiry: '',
    });
    const [success, setSuccess] = useState(false);

    const handleInputChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (
            formData.name !== '' &&
            formData.company !== '' &&
            formData.email !== '' &&
            formData.service !== '' &&
            formData.hear !== '' &&
            formData.enquiry !== ''
        ) {
            document.getElementById('contactForm').reset();
            setSubmitDisabled(true);

            fetch(process.env.GATSBY_MAIL_URL, {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(
                () => {
                    setFormData((prevState) => ({
                        ...prevState,
                        name: '',
                        company: '',
                        email: '',
                        service: '',
                        hear: '',
                        enquiry: '',
                    }));

                    setSuccess(true);
                },
                (error) => {
                    console.info(error);
                }
            );
        } else {
            let tmpFormClass = formClass;

            if (formData.name === '') {
                tmpFormClass.name = 'formerror';
            }

            if (formData.company === '') {
                tmpFormClass.company = 'formerror';
            }

            if (formData.email === '') {
                tmpFormClass.email = 'formerror';
            }

            if (formData.service === '') {
                tmpFormClass.service = 'select formerror';
            }

            if (formData.hear === '') {
                tmpFormClass.hear = 'select formerror';
            }

            if (formData.enquiry === '') {
                tmpFormClass.enquiry = 'formerror';
            }

            setFormClass((prevState) => ({
                ...prevState,
                tmpFormClass
            }));
        }
    };

    return (
        <div className="form">
            {success === true ? (
                <div className="formsuccess">
                    <h4>Thank you!</h4>
                    <p>
                        Your appointment request has been successfully
                        submitted. We will be in-touch soon.
                    </p>
                </div>
            ) : (
                <form method="post" id="contactForm" onSubmit={handleSubmit}>
                    <div className="input-container">
                        <label htmlFor="name">
                            <span className="label">
                                Name <strong>*</strong>
                            </span>
                            <input
                                className={formClass.name}
                                name="name"
                                type="text"
                                placeholder="Your Name (required)"
                                onBlur={handleInputChange}
                                autoComplete="off"
                            />
                        </label>
                    </div>
                    <div className="input-container">
                        <label htmlFor="company">
                            <span className="label">Company</span>
                            <input
                                className={formClass.company}
                                name="company"
                                type="text"
                                placeholder="Company Name"
                                onBlur={handleInputChange}
                                autoComplete="off"
                            />
                        </label>
                    </div>
                    <div className="input-container">
                        <label htmlFor="email">
                            <span className="label">
                                Email Address <strong>*</strong>
                            </span>
                            <input
                                className={formClass.email}
                                name="email"
                                type="email"
                                placeholder="Your Email Address (required)"
                                onBlur={handleInputChange}
                                autoComplete="off"
                            />
                        </label>
                    </div>
                    <div className="input-container">
                        <label htmlFor="services">
                            <span className="label">Service</span>
                            <div className={formClass.service}>
                                <select
                                    name="service"
                                    onBlur={handleInputChange}
                                >
                                    <option defaultValue hidden>
                                        Choose an option
                                    </option>
                                    <option value="Superannuation">
                                        Superannuation
                                    </option>
                                    <option value="Insurance">Insurance</option>
                                    <option value="Estate Planning">
                                        Estate Planning
                                    </option>
                                    <option value="Investment">
                                        Investment
                                    </option>
                                </select>
                            </div>
                        </label>
                    </div>
                    <div className="input-container">
                        <label htmlFor="hear">
                            <span className="label">
                                How did you hear about us?
                            </span>
                            <div className={formClass.hear}>
                                <select name="hear" onBlur={handleInputChange}>
                                    <option defaultValue hidden>
                                        How did you hear about us?
                                    </option>
                                    <option value="Google Search">
                                        Google Search
                                    </option>
                                    <option value="Through a Friend">
                                        Through a friend
                                    </option>
                                    <option value="Social Media">
                                        Social Media
                                    </option>
                                    <option value="LinkedIn">LinkedIn</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </label>
                    </div>
                    <div className="input-container">
                        <label htmlFor="enquiry">
                            <span className="label">
                                Enquiry <strong>*</strong>
                            </span>
                            <textarea
                                className={formClass.enquiry}
                                name="enquiry"
                                placeholder="Tell us more about the service required"
                                onBlur={handleInputChange}
                                autoComplete="off"
                            ></textarea>
                        </label>
                    </div>
                    <div className="input-container">
                        <button
                            type="submit"
                            className="btn"
                            disabled={submitDisabled}
                        >
                            Send
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default Form;
